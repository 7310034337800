import React from "react";
import { RowEntry } from "./RowEntry";


const StudyForSuccess = props => (<React.Fragment>
  <RowEntry
    miniMagUrl="https://drive.google.com/file/d/1zMBsXPrg2caQnNm1vTCv4vyvUTv--oCC/view?usp=sharing"
    colors={{
      primary: '#FDFDFD',
      secondary: '#FDFDFD',
      background: '#262262',
    }}
  >
    <div className="title">Study For Success</div>
    <div className="hook">Your Guide to Greatness</div>
    <div className="background"></div>
    <div className="words">
      Knowing what type of learner you are can drastically decrease
      the stress associated with studying.
      In this seminar, we explore a variety of learning styles;
      going beyond just the visual, auditory and kinesthetic.
    </div>
  </RowEntry>
  <style jsx>{`
    .title, .hook {
      font-family: Telegrahico;
      line-height: 1.1;
    }
    .background {
      background-image: url("../images/past-events/Study-for-Success-Background-Only.png");
      background-position: right;
      background-size: auto 100%;
      filter: brightness(120%) saturate(75%) hue-rotate(0deg);
      opacity: 0.15;
    }
  `}</style>
</React.Fragment>);

export { StudyForSuccess };
