import React from "react";
import PropTypes from "prop-types";

const testimonials = [
  { // from study essentials 101
    author: "Rachel",
    comment: `When all these strategies learned are implemented,
      I believe that success will be earned and
      definitely shift towards an upward climb.`,
  },
  { // from study for success
    author: "Anjali",
    comment: `Thank you for the seminar. I do not regret attending it,
      and this would stick with me for a lifetime.
      The team was very efficient in replying to comments,
      and with the setup etc.`,
  },
  { // from study for success
    author: "Alainaj",
    comment: `I look forward to attending more sessions in the future.
      It was enjoyable and educational.`,
  },
];

const TextTestimonials = props => {
  return (<React.Fragment>
    <div className="self">
      <div className="container">
        {testimonials.map(({author, comment}) => (<div className="testimonial">
          <span className="comment">{comment}</span>
          <span className="author">{author}</span>
        </div>))}
      </div>
    </div>
    <style jsx>{`
      .self{
        color: white;
        font-family: Lato;
      }
      .container {
        display: flex;
        gap: 1em;
      }
      .testimonial{
        background-color: #262262;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        
        text-align: justify;
        font-weight: 600;
        font-style: italic;
        position: relative;
      }
      .testimonial span {
        position: relative;
        display: block;
      }

      .testimonial .comment::before {
        content: '“';
        margin-left: -0.45em;
        top: -0.25em;
      }
      .testimonial .comment::after {
        content: '”';
        margin-left: -0.1em;
        transform: translateY(-0.25em);
      }
      .testimonial .comment::before,
      .testimonial .comment::after {
        position: absolute;
        font-size: 180%;
      }
      .author {
        margin-top: 0.3em;
        text-align: right;
      }
      .testimonial .author::before {
        content: '~';
      }

      .container {
        flex-direction: column;
        align-items: center;
      }
      .testimonial {
        width: 90%;
        padding: 2em;
      }

      @from-width desktop{
        .testimonial {
          width: 20em;
          padding: 2em;
        }
        .container {
          flex-direction: row;
          align-items: stretch;
        }
      }
    `}</style>
  </React.Fragment>)
}


export { TextTestimonials };

