import React from "react";
import PropTypes from "prop-types";

const Awards = props => {
  return (<React.Fragment>
    <div className="self">
      <div className="award stars">
        <div className="images">
          <img src="../images/awards/Stars-of-Tomorrow.jpg"/>
        </div>
        <p>
          National Youth Action Committee (NYAC)<br/>
          Top 20 Stars of Tomorrow
        </p>
      </div>
      <div className="award ttcsi">
        <div className="images">
          <img src="../images/awards/TTCSI-1.jpg"/>
          <img src="../images/awards/TTCSI-2.jpg"/>
        </div>
        <p>
          Trinidad and Tobago Coalition of Service Industries (TTSCI) <br/>
          Young Service Provider of the Year Nominee (twice)
        </p>
      </div>
    </div>
    <style jsx>{`
      .self {
        display: flex;

        font-size: 150%;
        flex-direction: column;
        gap: 0.5em;

        @from-width tablet {
          flex-direction: row;
          gap: 1.0em;
        }
        @from-width desktop {
          flex-direction: row;
          gap: 1.0em;
          font-size: 170%;
        }
      }
      .award {
        padding: 0 0.5em;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        text-align: center;
        max-width: 14em;
      }
      .award .images {
        width: 10em;
        padding: 0.1em;
        box-shadow: 0 0 0.1em hsl(0, 0%, 50%);
        height: 13.3em;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 0.2em
      }
      
      .award img {
        width: 100%;
      }
      .award p {
        font-size: 90%;
        margin-top: 0.2em;
        font-family: Lato;
        font-weight: 600;
        color: #262262;
      }
    `}</style>
  </React.Fragment>)
}


export { Awards };
