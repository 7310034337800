import PropTypes from "prop-types";
import React from "react";
import { graphql, Link } from "gatsby";

import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
import Hero from "../components/Hero";
import { Body } from "../components/HomeGraphics";
import Seo from "../components/Seo";
import CountdownTimer from "../components/CountdownTimer"

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

    // When the user clicks the button, open the modal
    showModal(){
      document.querySelector('dialog').showModal();
    }

    // When the user clicks on <span> (x), close the modal
    closeModal() {
      document.querySelector('dialog').close();
    }



  render() {

    const {
      data: {
        posts: { edges: posts = [] },
        bgDesktop: {
          resize: { src: desktop }
        },
        bgTablet: {
          resize: { src: tablet }
        },
        bgMobile: {
          resize: { src: mobile }
        },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = this.props;

    const backgrounds = {
      desktop,
      tablet,
      mobile
    };




    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Hero scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} />
          )}
        </ThemeContext.Consumer>

        <ThemeContext.Consumer>
          {theme => (
            <Body scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} />
          )}
        </ThemeContext.Consumer>

        <hr ref={this.separator} />


        <section id="subscribe">
          <p>Subscribe for updates on future deals, events and opportunties.</p>
            <button id="subBtn" className="button">
              <Link
                to='/Subscribe'
                className="button"
              >
              <span>Subscribe Now </span>
            </Link>
          </button>

        </section>

        <section id="contact" className="blue">
             <h1 className="lg">Contact Us</h1>

            <div>
              <h2>Email Address: <a href="mailto:info@mystudycommunity.com">info@mystudycommunity.com</a></h2>

              <div id="social">
                <a href="https://www.facebook.com/ParadigmShiftSeminar1/" target="_blank" rel="noopener noreferrer">
                  <svg className="niftybutton-facebook"  data-tag="fac" data-name="Facebook" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
                    <path d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0 0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z"></path>
                  </svg>
                </a>
                <a href="https://www.instagram.com/mystudycommunity" target="_blank" rel="noopener noreferrer" >
                  <svg className="niftybutton-instagram"  data-tag="ins" data-name="Instagram" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
                    <path d="M256 109.3c47.8 0 53.4 0.2 72.3 1 17.4 0.8 26.9 3.7 33.2 6.2 8.4 3.2 14.3 7.1 20.6 13.4 6.3 6.3 10.1 12.2 13.4 20.6 2.5 6.3 5.4 15.8 6.2 33.2 0.9 18.9 1 24.5 1 72.3s-0.2 53.4-1 72.3c-0.8 17.4-3.7 26.9-6.2 33.2 -3.2 8.4-7.1 14.3-13.4 20.6 -6.3 6.3-12.2 10.1-20.6 13.4 -6.3 2.5-15.8 5.4-33.2 6.2 -18.9 0.9-24.5 1-72.3 1s-53.4-0.2-72.3-1c-17.4-0.8-26.9-3.7-33.2-6.2 -8.4-3.2-14.3-7.1-20.6-13.4 -6.3-6.3-10.1-12.2-13.4-20.6 -2.5-6.3-5.4-15.8-6.2-33.2 -0.9-18.9-1-24.5-1-72.3s0.2-53.4 1-72.3c0.8-17.4 3.7-26.9 6.2-33.2 3.2-8.4 7.1-14.3 13.4-20.6 6.3-6.3 12.2-10.1 20.6-13.4 6.3-2.5 15.8-5.4 33.2-6.2C202.6 109.5 208.2 109.3 256 109.3M256 77.1c-48.6 0-54.7 0.2-73.8 1.1 -19 0.9-32.1 3.9-43.4 8.3 -11.8 4.6-21.7 10.7-31.7 20.6 -9.9 9.9-16.1 19.9-20.6 31.7 -4.4 11.4-7.4 24.4-8.3 43.4 -0.9 19.1-1.1 25.2-1.1 73.8 0 48.6 0.2 54.7 1.1 73.8 0.9 19 3.9 32.1 8.3 43.4 4.6 11.8 10.7 21.7 20.6 31.7 9.9 9.9 19.9 16.1 31.7 20.6 11.4 4.4 24.4 7.4 43.4 8.3 19.1 0.9 25.2 1.1 73.8 1.1s54.7-0.2 73.8-1.1c19-0.9 32.1-3.9 43.4-8.3 11.8-4.6 21.7-10.7 31.7-20.6 9.9-9.9 16.1-19.9 20.6-31.7 4.4-11.4 7.4-24.4 8.3-43.4 0.9-19.1 1.1-25.2 1.1-73.8s-0.2-54.7-1.1-73.8c-0.9-19-3.9-32.1-8.3-43.4 -4.6-11.8-10.7-21.7-20.6-31.7 -9.9-9.9-19.9-16.1-31.7-20.6 -11.4-4.4-24.4-7.4-43.4-8.3C310.7 77.3 304.6 77.1 256 77.1L256 77.1z"></path>
                    <path d="M256 164.1c-50.7 0-91.9 41.1-91.9 91.9s41.1 91.9 91.9 91.9 91.9-41.1 91.9-91.9S306.7 164.1 256 164.1zM256 315.6c-32.9 0-59.6-26.7-59.6-59.6s26.7-59.6 59.6-59.6 59.6 26.7 59.6 59.6S288.9 315.6 256 315.6z"></path>
                    <circle cx="351.5" cy="160.5" r="21.5"></circle>
                  </svg>
                </a>
                {/* Obtained from social-icon-generator.com */}
                <a href="https://www.tiktok.com/@mystudycommunity" target="_blank" rel="noopener noreferrer" >
                  <svg className="niftybutton-instagram"  data-tag="ins" data-name="Instagram" viewBox="3 0 45 45" preserveAspectRatio="xMidYMid meet">
                    <path d="M24.7 10v19.14a4.05 4.05 0 01-7.28 2.44 4.05 4.05 0 014.46-6.32v-4.9a8.86 8.86 0 00-7.55 14.97 8.86 8.86 0 0015.2-6.2v-9.77a11.46 11.46 0 006.67 2.14v-4.8a6.76 6.76 0 01-4.95-2.17 6.68 6.68 0 01-1.73-3.91V10H28.2z" clip-rule="evenodd" fill-rule="evenodd"/>
                  </svg>
                </a>
                {/* Obtained from social-icon-generator.com */}
                <a href="https://www.youtube.com/watch?v=G4zQ1VLzAHw&list=PLrIzGv9OlZl8Jhqt1-32ZDTio6p6CA8BT" target="_blank" rel="noopener noreferrer" >
                  <svg  data-tag="ins" data-name="Instagram" viewBox="9 6 32 32" preserveAspectRatio="xMidYMid meet" >
                      <path d="M24 14.2s-8.76 0-10.94.59a3.5 3.5 0 00-2.48 2.47C10 19.44 10 24 10 24s0 4.56.58 6.74a3.5 3.5 0 002.48 2.48c2.18.58 10.94.58 10.94.58s8.75 0 10.94-.58a3.5 3.5 0 002.47-2.48C38 28.56 38 24 38 24s0-4.56-.59-6.74a3.5 3.5 0 00-2.47-2.47C32.75 14.2 24 14.2 24 14.2zm-2.8 5.6l7.27 4.2-7.27 4.2z"></path>
                  </svg>
                </a>
              </div>
            </div>
        </section>

        <style jsx>{`



      dialog:not([open]) {
          display: none;
      }

        #joinBtn:hover{
          backgroun-color: #00A79D;
        }

      #join{
          background:url(./images/button.png) no-repeat;
          cursor:pointer;
          border:none;
      }

      #join:hover  /* use Dot here */
      {
          background:url(./images/button2.png) no-repeat;
      }

        #contact{
          min-height: 170px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-bottom: 4.5em;
        }
        @from-width desktop{
          #contact{
            padding-bottom: 2vh;
          }
        }

        #contact a {
          color: white;
          text-decoration: underline;
        }

        #subscribe{
          min-height: 200px;
        }

        #contact h2{
          margin-bottom: 10px;
        }

        #social{
          display:flex;
          justify-content: center;
        }

        #social a  {
          text-decoration: none;
          border: 0;
          width: 35px;
          height: 35px;
          padding: 2px;
          margin: 5px;
          color: #ffffff;
          border-radius: 15%;
          background-color: #262262;
        }

        #social svg {
          display:block;
          fill:currentColor;
        }

        h1{
          margin-top: 15px;
          margin-bottom: 15px;
        }

        #seminars{
          display: flex;
          flex-direction: column;
        }

        #seminars > article {
          display: flex;
          flex-direction: row;
        }

        li {
          list-style-position: inside;
        }

        #seminars > article > article:nth-child(1){
          display: flex;
          text-align: left;
          color: white;
          flex-grow: 1;
          padding: 10px;
          align-items: center;

        }

        #seminars > article > article:nth-child(2){
          display: flex;
          flex-grow: 3;
          padding: 10px;
          flex-direction: column;
          align-items: flex-end;

        }

        #seminars > article > article:nth-child(2) > p {
          display: flex;
          flex-grow: 1;
        }

        #seminars > article > article:nth-child(2) > iframe {
          margin: 10px;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
          transition: 0.3s;
        }

        #semLinks {
          font-size: 25px;
          flex-direction: column;
          justify-content: flex-start;
        }

        #semLinks a{
          color:white;
          text-decoration: underline;
        }

        #semLinks ul{
          margin-top: 15px;
        }

        .hr{
          border: 2px solid white;
          margin-top: 30px;
          margin-bottom: 30px;
          height: 2px;
          width: 60vw;
          min-width: 400px;
          background-color: white;
        }

        #second{
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        #second > p {
          font-size: 30px;
          margin: 20px;
        }

        iframe {

        }

        iframe:hover{
          box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        }

        #calFrame{
            width: 90vw;
            height: 400px;
        }

        @media only screen and (max-width: 600px) {
          p{
              font-size: 20px;
          }

          .md{
            font-size:20px;
          }

          .lg{
            font-size:28px;
          }

          #seminars > article {
            flex-direction: column;
          }

          #vidFrame{
            width:85vw;
            height:200px;
          }

        }

        @media only screen and (min-width: 601px) {
          p{
            font-size: 30px;
          }

          .md{
            font-size: 30px;
          }

          .lg{
            font-size: 38px;
          }

          #calFrame{

          }

          #vidFrame{
            width: 40vw;
            height: 400px;
          }

        }

        .two-column{
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: flex-start;
        }

        .two-column > div{
            align-items: center;
            width: 40%;
        }

        .single-column{
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        #second{
          padding-top: 5vh;
        }

        CountdownTimer{
          display:flex;
          flex-direction: row;
        }

        #reg{
          margin-top: 40px;
        }

        hr {
          margin: 0;
          border: 0;
        }

        section{
          text-align: center;
          padding: 2vh;
          min-height: 70vh;
        }

        .left{
          text-align: left;
        }

        .blue{
          background-color: #262262;
          color: white;
        }

        .right{
          text-align: right;
        }

        .button {
          display: inline-block;
          vertical-align:middle;
          border-radius: 4px;
          background-color: #262262;
          border: none;
          color: #FFFFFF;
          text-align: center;
          font-size: 25px;
          padding: 10px;
          width: 300px;
          transition: all 0.5s;
          cursor: pointer;
          margin: 20px;
        }

        .button span {
          color: white;
          cursor: pointer;
          display: inline-block;
          position: relative;
          transition: 0.5s;
        }

        .button span:nth-child(2){
          font-size: 15px
        }

        .button span:nth-child(1):after {

          position: absolute;
          opacity: 0;
          top: 0;
          bottom: 5px;
          right: 20px;
          transition: 0.5s;
        }

        #subBtn span:nth-child(1):after{
          content: '🔔';
        }

        #joinBtn span:nth-child(1):after {
          content: '🎫';
        }

        .button:hover span {
          padding-right: 50px;
        }

        .button:hover span:after {
          opacity: 1;
          right: 0;
        }

        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

//hero-background
