import React from "react";
import PropTypes from "prop-types";

import { FaAngleRight, FaAngleLeft } from "react-icons/fa/";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const styledImage = (src, extraStyle) => (
  <div className="image" style={Object.assign({'background-image': `url(${src})`}, extraStyle)}></div>
);
const fixedHeightImage = src => styledImage(src, {backgroundSize: "auto 100%"});
const fixedWidthImage = src => styledImage(src, {backgroundSize: "100% auto"});
const containedImage = src => styledImage(src, {backgroundSize: "contain"});
const simpleImage = src => styledImage(src, {});


const slides = [
  {
    caption: 'Daaga Hall',
    contents: simpleImage('../images/carousel/IMG_3371.JPG'),
  },
  {
    caption: 'About us',
    contents: fixedWidthImage('../images/carousel/vlcsnap-2021-09-18-13h16m53s890.png'),
  },
  {
    caption: 'People raising hands',
    contents: simpleImage('../images/carousel/Para-2970.JPG'),
  },
  {
    caption: 'TTCSI',
    contents: fixedHeightImage('../images/carousel/IMG_99040.jpg'),
  },
  {
    caption: 'Presentation',
    contents: simpleImage('../images/carousel/IMG_2526.JPG'),
  },
  {
    caption: 'Group photo',
    contents: simpleImage('../images/carousel/Para-2987.JPG'),
  },
  {
    caption: 'Paradigm',
    contents: fixedWidthImage('../images/carousel/vlcsnap-2021-10-12-21h38m19s729.png'),
  },
].map(image => {
  return image;
});
const Carousel = props => {
  return (<React.Fragment>
    <div className="self">
      <div className="slide-container">
        <Slide
          easing="ease"
          duration="3000"
          transitionDuration="150"
          indicators="true"
          prevArrow={<FaAngleLeft className="nav-arrow"/>}
          nextArrow={<FaAngleRight className="nav-arrow"/>}
          indicators={i => (<li key={i} className="indicator"><button className="each-slideshow-indicator"/> </li>)}
        >
          {slides.map(({caption, contents}, index) => (
            <div className="each-slide" key={index}>
              {/*<div className="info">
                <div className="caption">{caption||""}</div>
                <div>{index+1}/{slides.length}</div>
              </div>*/}
              {contents}
            </div>
          ))}
        </Slide>
      </div>
    </div>
    <style jsx>{`
      .self {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .slide-container {
        :global(.react-slideshow-container){
          box-shadow: 0 1px 2px hsla(0, 0%, 0%, 20%);
        }
        :global(.nav) {
          opacity: 0;
        }
        :hover :global(.nav) {
          opacity: 1;
        }
        :global(.nav-arrow[data-type=next]) {
          transform: translateX(-0.3em);
          margin-left: -1.9em;
        }
        :global(.nav-arrow[data-type=prev]) {
          transform: translateX(0.3em);
          margin-right: -1.9em;
        }
        :global(.nav-arrow) {
          height: 1.9em;
          width: 1.9em;
          background: hsla(0, 0%, 70%, 0.6);
          color: hsla(0, 0%, 100%, 0.9);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        :global(.nav-arrow:hover) {
          background: hsla(0, 0%, 90%, 0.9);
          color: #666666;
        }
        :global(.indicators) {
          margin-top: 0.5em;
        }
      }
      .indicator.active .each-slideshow-indicator{
        opacity: 0.75;
        :focus {
          opacity: unset;
        }
      }

      .slide-container{
        max-width: 100%;
        @from-width desktop {
          width: 70em;
        }
      }
      .each-slide {
        position: relative;
        min-height: max(40vh, 15em);
        max-width: 100%;
        @from-width tablet {
          min-height: max(40vh, 23em);
        }
        @from-width desktop {
          min-height: unset;
          height: 30em;
        }
      }

      .info{
        z-index: 10;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        bottom: 0px;
        display: flex;
        justify-content: space-between;
        background-color: hsla(0, 0%, 20%, 50%);
        padding: 0.4em 0.7em;
        color: white;
        opacity: 0;
      }
      .each-slide:hover .info{
        opacity: 1;
      }

      .each-slide > :global(:not(.info)) {
        width: 100%;
        height: 100%;
        position: absolute;
      }

      .each-slide :global(.image){
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    `}</style>
  </React.Fragment>)
};

export { Carousel };
