import React from "react";
import PropTypes from "prop-types";

import { Carousel } from "./Carousel";

const Hero = props => {
  const { scrollToContent, backgrounds, theme } = props;

  return (
    <React.Fragment>
      <div className="self">
        <Carousel />
      </div>
      <style jsx>{`
        .self {
          margin-top: ${`calc(${theme.header.height.homepage} + 10px)`};
        }
      `}</style>
    </React.Fragment>
  );
};

Hero.propTypes = {
  scrollToContent: PropTypes.func.isRequired,
  backgrounds: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default Hero;
