import React from "react";
import PropTypes from "prop-types";

import { RowEntry } from "./RowEntry";

import {StudyEssentials} from "./StudyEssentials.js";
import {StudyForSuccess} from "./StudyForSuccess.js";
import {ParadigmShift} from "./ParadigmShift.js";

const Gayelle = props => (<React.Fragment>
  <div className="self">
    <div className="logo"></div>
    <div className="words">
      Gayelle is the Caribbean's Global Television station
      and remains committed to non-fiction Caribbean Health
      and Wellness content.
    </div>
    <a className="facebook" href="https://www.facebook.com/GayelleTheCaribbean/live_videos">
      <div className="shadow"></div>
      <div className="logo"></div>
    </a>
    <a className="tego" href="https://tegostream.com/tv?channel=864">
      <div className="shadow"></div>
      <div className="logo"></div>
    </a>
  </div>
  <style jsx>{`
    .self{
      font-size: 110%;
      padding: 1em 1.5em;
      display: grid;
      grid-template-areas:
        "logo words words words"
        "logo facebook tego filler";
      grid-template-columns: 6.5em minmax(6em, 1fr) minmax(6em, 1fr) auto;
      gap: 0.1em;
      @from-width desktop {
        font-size: 130%;
      }
    }
    .logo {
      grid-area: logo;
      background-image: url('../images/past-events/Gayelle-Logo-Variants-01.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 6.5em;
      filter: brightness(110%);
      align-self: stretch;
    }
    .words {
      grid-area: words;
      padding: 0.2em 1em;
      font-family: Lato;
      color: #262262;
      max-width: 23em;
    }
    .facebook {
      grid-area: facebook;
      .logo{ background-image: url('../images/past-events/facebook.png'); }
    }
    .tego {
      grid-area: tego;
      .logo { background-image: url('../images/past-events/Tego-Stream-logo-purple-197x56.png'); }
    }

    a {
      position: relative;
      height: 1.7em;
      margin: 0 0.5em;
      box-sizing: content-box;
      div{
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .shadow {
        border-radius: 0.8em;
      }
      :hover .shadow{
        background: hsla(0, 0%, 80%, 0.3);
      }
    }
  `}</style>
</React.Fragment>)

const PastEvents = props => {
  return (
    <React.Fragment>
      <div className="events">
        <StudyForSuccess />
        <StudyEssentials />
        <ParadigmShift />
      </div> 
      <div className="gayelle-intro">
        Our online seminars are conducted via Zoom and utilize various audio-visual technologies
        to ensure excellent quality and word-class standards.
        This is done with our partner Gayelle The Caribbean.
      </div>
      <div className="gayelle">
        <Gayelle/>
      </div>
      <style jsx>{`
        .events{
          display: flex;
          max-width: calc(100% - 2em);
          gap: 1em;
          flex-direction: column;
          @from-width desktop {
            flex-direction: row;
            justify-content: center;
          }
        }
        .gayelle-intro {
          margin-top: 1em;
          padding: 0 1em;
          max-width: 52em;
          text-align: center;

        }
        .events > :global(*) {
          @from-width desktop {
            width: 21em;
          }
        }
      `}</style>
    </React.Fragment>
  )
};

export { PastEvents };
