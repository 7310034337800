import React from "react";
import PropTypes from "prop-types";

const RowEntry = props => {
  const {miniMagUrl, colors, children} = props;

  const filterByClass = f => children.filter(c => f(c.props.className || ''));

  return (
    <React.Fragment>
      <section className="entry self">
        <div className="background"></div>
        {filterByClass(c => c.includes('background'))}
        <div className="content">
          {filterByClass(c => c.includes('title'))}
          {filterByClass(c => c.includes('hook'))}
          {filterByClass(c => c.includes('words'))}
          <div className="spacer"></div>
          <a className="mini-mag" href={miniMagUrl}>Mini Magazine</a>
        </div>
      </section>
      {/* --- STYLES --- */}
      <style jsx>{`
        .self {
          display: flex;
          position: relative;
          overflow: hidden;
          color: ${colors.primary};
        }
        .background {
          background-color: ${colors.background};
        }
        .self :global(.background) {
          z-index: -10;
          position: absolute;
          height:100%;
          width: 100%;

          transition: all 2s cubic-bezier(0.19,1,0.22,1) 50ms;
          background-repeat: no-repeat;
        }
        .self:hover :global(.background) {
          transition: all 2s cubic-bezier(0.19,1,0.22,1) 50ms;
          transform: scale(1.10);
        }

        .content {
          width: 100%;
          padding: 0.8em;
          position: relative;
          display: flex;
          flex-direction: column;
        }

        .self :global(.title) {
          // margin: 0 0.5%;
          font-weight: 600;
          font-size: 200%;
        }
        .self :global(.hook) {
          // margin: 0 0.5%;
          font-size: 130%;
          margin-top: -0.5%;
        }
        .self :global(.words) {
          padding: 0.5em 1em;
          margin: 0 0.5%;
        }
        .self :global(.bolded){
          font-weight: bolder;
        }

        .spacer {
          flex-grow: 1;
        }
        .mini-mag {
          align-self: flex-end;
          font-size: 120%;
          font-weight: 600;
          
          color: ${colors.secondary};

          padding: 0.1em 0.3em;
          background-color: ${colors.background};
          border: 0.25em solid ${colors.secondary};
          border-radius: 0.4em;

          transition: all 0.4s cubic-bezier(0.19,1,0.22,1);
        }

        .mini-mag:hover{
          filter: brightness(80%) saturate(120%) contrast(120%);
        }
        .mini-mag:active{
          transition: all 0.1s cubic-bezier(0.19,1,0.22,1);
          filter: brightness(70%) saturate(150%) contrast(170%);
        }
      `}</style>
    </React.Fragment>
  )
};

RowEntry.propTypes = {
  miniMagUrl: PropTypes.string,
  colors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    background: PropTypes.string,
  }),
};

export { RowEntry };
