import React from "react";
import { RowEntry } from "./RowEntry";

const colors = {
  primary: "#141438",
  secondary: "#141438",
  background: "#32FFEF",
};

const StudyEssentials = props => (<React.Fragment>
  <RowEntry
    miniMagUrl="https://drive.google.com/file/d/1A8hEOCZWMgvkmW3__OUgIRsQmFuz0J-i/view"
    colors={colors}
  >
    <div className="title">
      Study Essentials<span>101<span></span></span>
    </div>
    <div className="background"></div>
    <div className="words">
      Online learning has undoubtedly increased students'
      stress levels exponentially,
      particularly in regard to tight deadlines and numerous exams.
      In this seminar, we present <span className="bolded">THE SECRET</span> technique to <span className="bolded">stop procrastination</span>, delve into the importance of <span className="bolded">timetabling and time management</span> and we teach you how to deal with <span className="bolded">exam stress</span>.
    </div>
  </RowEntry>
  <style jsx>{`
    .title {
    }
    .title > span{
      vertical-align: top;
      font-size: 95%;
      margin-left: 0.3em;
      position: relative;
      color: ${colors.background};
      font-weight: 700;
    }
    .title > span > span {
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 58%;
      width: 115%;
      background-color: ${colors.primary};
      transform: translate(-50%, -45%) rotateZ(-6deg);
    }
    .background {
      background-image: url("../images/past-events/STUDY-ESSENTIALS-101-Background-Only.png");
      background-position: right;
      background-size: auto 100%;
      filter: brightness(100%) saturate(105%) hue-rotate(00deg);
      opacity: 0.3;
    }
  `}</style>
</React.Fragment>);


export { StudyEssentials };

