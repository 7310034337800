import React from "react";
import { RowEntry, Style } from "./RowEntry";

const colors = {
  primary: "#3D56a6",
  secondary: "#3D56a6",
  background: "white",
};

const ParadigmShift = props => (<React.Fragment>
  <div className="self">
    <div className="border"></div>
    <RowEntry
      miniMagUrl="https://drive.google.com/file/d/1UF6FuUIgB935eWUzG_Wm_QR7nYbMgPdb/view?usp=sharing"
      colors={colors}
    >
      <div className="title">
        <span>PARADIGM</span>
        <span>SHIFT</span>
      </div>
      <div className="background image"></div>
      <div className="background"></div>
      <div className="words">
        Sometimes it's difficult to find motivation to study; especially in a stress free manner.
        Paradigm Shift will teach you the secrets to <span className="bolded">enjoy studying</span> while completing the same amount of work.
        This will help you get those A's <span className="bolded">and still have an active social life!</span>
      </div>
    </RowEntry>
  </div>
  <style jsx>{`
    .self {
      position: relative;
    }
    .self > :global(.entry) {
      width: 100%;
      height: 100%;
    }
    .self > .border {
      position: absolute;
      border: 0.4em solid #329dcc;
      width: 100%;
      height: 100%;
    }
    .title *:first-child{
      color: #6DCBDF;
      font-family: Urbano;
    }
    .title *:last-child{
      color: #329DCC;
      font-family: "Urbano-Bold";
    }
    .background.image {
      background-image: url('../images/pst.png');
      background-position: right;
      background-size: auto 80%;
      filter: brightness(120%) saturate(75%) hue-rotate(0deg);
      opacity: 0.1;

      @from-width desktop {
        background-position: center;
      }
    }
  `}</style>
</React.Fragment>);


export { ParadigmShift };

