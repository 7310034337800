import React from "react";
import PropTypes from "prop-types";

import { Intro } from "./Intro";
import { PastEvents } from "./PastEvents";
import { HappyClients } from "./HappyClients";
import { TextTestimonials } from "./TextTestimonials";
import { Awards } from "./Awards";
import { Contact } from "./Contact";


const Body = ({theme}) => {
  return (<React.Fragment>
    <div className="self">
      <section>
        <Intro theme={theme} />
      </section>
      <section className="video">
        <div className="title">Secret Motivational Technique to study longer</div>
        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/G4zQ1VLzAHw"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
      </section>
      <section>
        <div className="title">Past Events</div>
        <PastEvents />
      </section>

      <section>
        <div className="title">Testimonials</div>
        <TextTestimonials />
      </section>

      <section>
        <div className="title">Awards</div>
        <Awards />
      </section>
    </div>
    <style jsx>{`
      .self {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
      }
      section {
        width: min(100vw, 70em);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1em 0;
      }
      section > .title{
        font-family: Lato;
        color: ${theme.color.brand.dark};
        font-size: 220%;
        font-weight: 600;
        margin-bottom: 0.3em;
        max-width: 100%;
        padding: 0 1em;
        text-align: center;
      }
      section > :global(:not(.title)) {
      }

      .video {
        .title {
          font-size: 170%;
          @from-width tablet {
            font-size: 220%;
          }
          @from-width desktop {
            font-size: 220%;
          }
        }
        iframe {
          max-width: 100%;
        }
      }
    `}</style>
  </React.Fragment>);
};

Body.propTypes = {
  theme: PropTypes.object.isRequired
};

export { Body };
