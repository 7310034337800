import React from "react";
import PropTypes from "prop-types";

const Intro = ({theme}) => {
  return (<React.Fragment>
    <div className="self">
      <div>
      We help students view studying in a more positive light so
      that they can excel academically and enjoy other aspects of life.
      </div>
    </div>
    <style jsx>{`
      .self {
        color: hsl(0, 0%, 20%);
        display: flex;

        font-size: 120%;
        padding: 0 2em;
        width: min(100%, 34em);
        text-align: center;
        @from-width tablet {
          font-size: 150%;
        }
        @from-width desktop {
          font-size: 150%;
        }
      }
    `}</style>
  </React.Fragment>)
}


export { Intro };
